import React from 'react'
import '../../style/Common/footer.css'

export default function FooterComponent() {
  return (
    <div id="footer">
        <svg viewBox="0 0 1440 320">
          <path id="footer_wave_one" className='footer_wave' d="M0,256L34.3,245.3C68.6,235,137,213,206,181.3C274.3,149,343,107,411,74.7C480,43,549,21,617,37.3C685.7,53,754,107,823,112C891.4,117,960,75,1029,58.7C1097.1,43,1166,53,1234,69.3C1302.9,85,1371,107,1406,117.3L1440,128L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          <path id="footer_wave_two" className='footer_wave' d="M0,32L40,64C80,96,160,160,240,165.3C320,171,400,117,480,117.3C560,117,640,171,720,186.7C800,203,880,181,960,186.7C1040,192,1120,224,1200,240C1280,256,1360,256,1400,256L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
          <path id="footer_wave_three" className='footer_wave' d="M0,224L26.7,213.3C53.3,203,107,181,160,181.3C213.3,181,267,203,320,192C373.3,181,427,139,480,138.7C533.3,139,587,181,640,192C693.3,203,747,181,800,181.3C853.3,181,907,203,960,213.3C1013.3,224,1067,224,1120,208C1173.3,192,1227,160,1280,170.7C1333.3,181,1387,235,1413,261.3L1440,288L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path>
        </svg>
    </div>
  )
}
