import React, {useEffect} from 'react'
import ExperienceComponent from '../components/Experience/ExperienceComponent';
import SidebarComponent from '../components/Common/SidebarComponent';
import {sendGAPageView} from "../common/js/ga4.js"

import '../style/Experience/experience.css';

export default function Experience() {
  useEffect(() => {
    sendGAPageView("/experience", "Experience")
  }, []);
  return (
    <div className="main_content">
      <SidebarComponent isHomePage={false}></SidebarComponent>
      <ExperienceComponent></ExperienceComponent>
    </div>
  )
}
