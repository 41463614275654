import React, {useEffect} from 'react'
import SidebarComponent from '../components/Common/SidebarComponent'
import JournalComponent from '../components/Journal/JournalComponent'
import {sendGAPageView} from "../common/js/ga4.js"

import "../style/Journal/journal.css"

export default function Journal() {
  useEffect(() => {
    sendGAPageView("/journal", "Journal")
  }, []);
  return (
    <>
    <div className="main_content">
      <SidebarComponent isHomePage={false}></SidebarComponent>
      <JournalComponent></JournalComponent>
    </div>
    </>
  )
}