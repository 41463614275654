import React, {useEffect} from 'react'
import ContactFormComponent from '../components/Contact/ContactFormComponent';
import '../style/Contact/contact.css'
import SidebarComponent from '../components/Common/SidebarComponent';
import {sendGAPageView} from "../common/js/ga4.js"

export default function Contact() {
  useEffect(() => {
    sendGAPageView("/contact", "Contact")
},[]);
  return (
         <div className="main_content">
         <SidebarComponent isHomePage={false}></SidebarComponent>
         <ContactFormComponent></ContactFormComponent>
      </div>
  )
}
