import React from 'react'

export default function LoaderComponent() {
  return (
    <div className="loader">
        <svg viewBox="0 0 100 100">
            <circle stroke-dasharray="164.93361431346415 56.97787143782138" r="35" stroke-width="5" stroke="#7f280a" stroke-linecap="round" fill="none" cy="50" cx="50" transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,7.105427357601002e-15,100)"></circle>
        </svg>
    </div>
  )
}

