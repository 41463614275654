import React, { useState, useEffect, useContext } from "react";
import { TabContext } from "../../App"
import {
  Link,
} from "react-router-dom";
const JournalComponent = () => {
  const {GetDataForPage} = useContext(TabContext)
  const [JournalData, setJournalData] = useState([]);
  const fetchData = async () => {
    let data = await GetDataForPage("Journal")
    setJournalData(data.entries)
  }
  
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div id="journal_container" className="flexCol">
        <div id="journal_box" className="flexCol">
            <div className="divTitle">
                <h2><strong>JOURNAL</strong></h2>
                <h3>My odd thoughts, here and there.</h3>
            </div> 
            <div id="journal_main">
                {JournalData.map(item => 
                                <div className="journal_item">
                                  <div className="journal_main">
                                  <div className="journal_top">
                                    <h2 className="journal_title"><a>{item.entry_title}</a></h2>
                                  </div>
                                  <h5 className="journal_date">{item.entry_date}</h5>
                                  <hr></hr>
                                  <div className="journal_body_preview">{item.entry_preview}...</div>
                                  </div>
                                  <Link className="journal_nav" to={`/journalentry/${item.id}`}><span className="journal_readMore">KEEP READING</span></Link>
                              </div>
                      )}
            </div>
        </div>
     </div>
    </>
  );
};

export default JournalComponent;
