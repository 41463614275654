exports.get_token = async () => {
  try {
    const res = {statusCode:null, data: null};
    const response = await fetch("/get-token", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }}); 
    res.statusCode = response.status;
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      return data.access;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: null}; 
  }
};