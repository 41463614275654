import React, { useState, useEffect, useContext } from "react";
import { TabContext } from "../../App"
import {readMoreClickedGAEvent, linkClickedGAEvent} from "../../common/js/ga4.js"
import {useNavigate} from "react-router-dom";
import LoaderComponent from "../Common/LoaderComponent.js";
import {aboutPageToExperiencePage} from "../../common/js/animate.js"
export default function AboutComponent() {  
  const [aboutCardData, setAboutCardData] = useState({});
  const {GetDataForPage} = useContext(TabContext)
  const [readingStepNum, setReadingStepNum] = useState(1);
  const [dataFetchStatus, setDataFetchStatus] = useState(-1);
  const paragraph_max_steps = 7;
  const UNICODE_BULLET = '\u2022';

  useEffect(() => { 
    fetchData(); 
  },[]);
  const navigate = useNavigate();
  const incrementStepNum = () => {  
    let currStep = readingStepNum
    if (currStep < paragraph_max_steps){
      readMoreClickedGAEvent(currStep);
      setReadingStepNum(currStep + 1);
      //scroll to element
      setTimeout(function (){
        let element = document.getElementsByClassName("about_paragraph more")[0];
        element.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth'});
      }, 100); 
    }
  }
  const skipToStepEnd = () => {
    setReadingStepNum(paragraph_max_steps);
    setTimeout(function (){
      let element = document.getElementsByClassName("spacer")[0];
      element.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth'});
    }, 100); 
  }
  const fetchData = async () => {
    let data = await GetDataForPage("About")
    if (data){
      setAboutCardData(data);
      setDataFetchStatus(1);
    }
    else{
      setDataFetchStatus(0);
    }
}
  return (
      <div id="about_container" className="flexCol">
        <div id="about_box">
        <div className="divTitle">
          <h2><strong>ABOUT ME</strong></h2>
          <h3>You can call me Aleks for short.</h3>
        </div>
        <div id="about_main">
          <div id="about_chat">
          <div id="about_chat_main">
            <div id="about_paragraph_container">
                <div className="about_paragraph left">
                    <p>I am a Polish-born, US-based full stack programmer with over 5 years of experience in the Fintech and E-Commerce industry.</p>
                </div>
                <div style={{display: readingStepNum >= 2 ? "block" : "none"}} className="about_paragraph left">
                  <p>Previously worked on <strong>.NET</strong>, <strong>Java</strong>, <strong>Xamarin</strong> & <strong>React</strong> projects among others - my current favorite stack is <strong>React.js</strong>!</p>
                </div>
                <div style={{display: readingStepNum >= 3 ? "block" : "none"}}  className="about_paragraph left">
                  <p>I have contributed to every step of the development lifecycle in a consulting environment.  I prioritize a positive, personal relationship with clients by being transparent and responsive. I have a knack for translating the technical into the non-technical, earning trust with those around me. </p> 
                </div>
                <div style={{display: readingStepNum >= 4 ? "block" : "none"}} className="about_paragraph left">
                  <p>As a <strong>team lead</strong>, I engage the team to its highest potential. No team member gets left behind, and I guide them until they can understand the requirements on their own terms. </p>
                </div>
                <div style={{display: readingStepNum >= 5 ? "block" : "none"}}  className="about_paragraph left">
                  <p>As a highly <strong>visual learner</strong>, I am always thinking of how we intake and interpret information on our screens.   The information must be conveyed intuitively and logically.   It must also be accessible to users taking advantage of a screen reader per accessibility standards. </p>
                </div>
                <div style={{display: readingStepNum >= 6 ? "block" : "none"}}  className="about_paragraph left">
                  <p>As a <strong>problem solver</strong>, I am thinking of new ideas of how I could improve the quality of my life and those around me.  The trick is to find the time to code them out!</p>
                </div>
                <div style={{display: readingStepNum >= 7 ? "block" : "none"}}  className="about_paragraph left">
                  <p>My core values are <strong>authenticity</strong>, <strong>simplicity</strong>, and <strong>enthusiasm</strong>.  There is no room for cutting corners!</p>
                  </div>
            </div>
            <div className="about_paragraph more">
              {readingStepNum < paragraph_max_steps ?
                <span onClick={() => { incrementStepNum()}} className="about_keep_reading">KEEP READING</span> 
                :
                <>
                  <span onClick={() => { aboutPageToExperiencePage(navigate)}} className="about_keep_reading">SEE MY WORK</span> 
                  <div className="spacer"></div>
                </>
              }
              {readingStepNum < paragraph_max_steps ?
                <span onClick={() => { skipToStepEnd()}} className="about_keep_reading">SKIP TO END</span>
                : <></> }
            </div>
          </div>
          <div className="about_chat_bot">
          </div>
          </div>
          <div id="about_info">
            <img alt="Self portrait by Aleks" src="/assets/img/about-me.png"></img>
            <div id="about_facts">
            {dataFetchStatus == -1 ? 
            <div className="loader_container">
                <LoaderComponent></LoaderComponent> 
            </div> 
            :
            <>
              <div className="about_card">
                <div className="card_container">
                    {aboutCardData["card_one"] == null ?(
                      <span>Oops! Data could not be retrieved.</span>
                    ):(             
                      aboutCardData["card_one"].map(item => 
                        <div className="flexCol aboutMeItem">
                          <div className='aboutMeQ'>
                            <label>{item.key}</label>
                          </div>
                          <div className='aboutMeA'>
                            <span className="about_item_value">{item.value}</span>
                          </div>
                        </div>)
                      )} 
                </div>
              </div>
            </>
            }
            </div>
          </div>
        </div>
        </div>
      </div>
    )
}
