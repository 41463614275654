export var getCookie = function(cookieName) { 
    let cookieKey = cookieName + "=";
    var _cookie = document.cookie.split(';');
    for(var i=0;i < _cookie.length;i++) {
        var c = _cookie[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(cookieKey) == 0) return c.substring(cookieKey.length,c.length);
    }
    return null;
};
var deleteCookie = function(cookieName) { 
    document.cookie = cookieName +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
//decode rot13 encoded string
var decodeRot13 = function(a) {
    return a.replace(/[a-zA-Z]/g, function(c){
      return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) 
                                 ? c : c - 26);
    })
}; 
//decode email and open mail app
export var openMailer = function(element) {
    var decoded = decodeRot13("nyrxfqbrfpbqr@tznvy.pbz");
    window.location.href = "mailto:"+decoded;
};

