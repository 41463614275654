import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { verify_captcha_backend } from "../../common/js/webservice.js";
import {hideShowContactForm, adjustContactFormHeight} from "../../common/js/animate.js"
import ReCAPTCHA from "react-google-recaptcha";
import {contactMeGAEvent} from "../../common/js/ga4.js"

const ContactFormComponent = (props) => {
  const [formValidated, setFormValidated] = useState(false);
  const [contactFormHeight, setContactFormHeight] = useState(0);
  const [contactResponseTitle, setContactResponseTitle] = useState("")
  const [contactResponseBody, setContactResponseBody] = useState("")
  const [captchaClassName, setCaptchaClassName] = useState("");
  const recaptchaReference = useRef(null);

  const onCaptchaChanged = async () => {
    setCaptchaClassName("");
  };
  const validateForm = () => {
    return document.querySelector("#formMessage").validity.valid && document.querySelector("#formName").validity.valid && document.querySelector("#formEmail").validity.valid;
  }
  const handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    if (validateForm()){
      if (recaptchaReference.current.getValue().length > 0){
        let msg_from_email = document.getElementById('formEmail');
        let msg_from_name = document.getElementById('formName');
        let msg_body = document.getElementById('formMessage');
    
        await verifyCaptchaAndSubmit(msg_from_name.value, msg_from_email.value, msg_body.value);
      }
      else{
        setCaptchaClassName("invalid");
        event.preventDefault();
        event.stopPropagation();
      }
    }
    else{
      adjustContactFormHeight(form, form.offsetHeight.toString(), form.scrollHeight.toString());
      event.preventDefault();
      event.stopPropagation();
    }
    setFormValidated(true);
  }
  //bring back form if error happened and user wants to go back
  const restoreContactForm = async () => {
    //reset captcha
    window.grecaptcha.reset();
    //show contact form & hide response
    let form = document.querySelector("#formContact");
    let formHeightStr = contactFormHeight.toString();
    let messageContainer = document.querySelector("#contactResponse");
    hideShowContactForm(form, formHeightStr,messageContainer, false);
  };
  const verifyCaptchaAndSubmit = async (form_name, form_email, form_body) => {
    let captchaval = recaptchaReference.current.getValue();
    //verify captcha on backend and send email if valid
    contactMeGAEvent("send");
    let res = await verify_captcha_backend(captchaval, form_name, form_email, form_body);
    if (res != null && res.data != null){
      //set success/fail message for user
      setContactResponseTitle(res.data.response_title)
      setContactResponseBody(res.data.response_body)
      //save original height of form & hide the form
      let form = document.querySelector("#formContact");
      let formHeightStr = form.offsetHeight.toString();
      let messageContainer = document.querySelector("#contactResponse");
      setContactFormHeight(formHeightStr);
      hideShowContactForm(form, formHeightStr,messageContainer, true);
      if (res.data.smtp_success == 1){
        contactMeGAEvent("send");
        //reset form and captcha
        document.getElementById("formContact").reset()
        window.grecaptcha.reset();
      }
      else{
        contactMeGAEvent("msg-fail");
      }
    }
    else{
      contactMeGAEvent("msg-success");
    }
  };
  return (
    <>
    <div id="contactFormContainer" className="flexCol">
      <div id="contactFormBox">
        <div className="divTitle">
            <h2><strong>CONTACT</strong></h2>
            <h3>Thanks for checking my site out. Send me feedback or get in touch with me here.</h3>
        </div>
        <div id="divContact">
          <Form id="formContact" className="formContactContainer" validated={formValidated} onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label><strong>Email Address</strong></Form.Label>
              <Form.Control required type="email" placeholder="Your Email" pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" />
              <Form.Control.Feedback type="invalid"> Provide a valid email.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label><strong>Name</strong></Form.Label>
              <Form.Control required type="textarea" placeholder="Your Name" pattern="[A-Za-z0-9 ]+" />
              <Form.Control.Feedback type="invalid"> Who's asking? Letters, numbers and spaces only.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Label><strong>Message</strong></Form.Label>
              <Form.Control required as="textarea" placeholder="Write a few words." minLength={25} maxLength={2000} rows={5} />
              <Form.Control.Feedback type="invalid">25 - 2000 characters.</Form.Control.Feedback>
            </Form.Group>
            <span className={captchaClassName} id="captchaFeedback">Verify Captcha</span>
            <ReCAPTCHA 
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} 
              ref={recaptchaReference} 
              onChange={onCaptchaChanged} className="reCaptcha" />
            <Button onClick={handleFormSubmit} variant="primary" size="lg" type="button">
              SEND
            </Button>
          </Form>              
        </div>
        <div id="contactResponse">
          <h2>{contactResponseTitle}</h2>
          <span>{contactResponseBody}</span>
          <button type="button" onClick={() => { restoreContactForm()}}>GO BACK TO FORM</button>
        </div>
      </div>
     </div>
    </>
  );
};

export default ContactFormComponent;
