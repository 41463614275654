import React, {useEffect} from 'react'
import WelcomeComponent from '../components/Home/WelcomeComponent.js';
import SidebarComponent from '../components/Common/SidebarComponent.js';
import HeaderComponent from '../components/Common/HeaderComponent.js';
import {sendGAPageView} from "../common/js/ga4.js"

import '../style/Home/home.css'

export default function Home() {
  useEffect(() => {
    sendGAPageView("/home", "Home")
  },[]);
  return (
    <>
    <WelcomeComponent></WelcomeComponent>
    </>
  );
}
