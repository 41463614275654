import React, {useEffect} from 'react'
import AboutComponent from "../components/About/AboutComponent"
import SidebarComponent from '../components/Common/SidebarComponent'
import {sendGAPageView} from "../common/js/ga4.js"

import "../style/About/about.css"


export default function About() {
  useEffect(() => {
    sendGAPageView("/about", "About")
  }, []);
  return (
    <>
    <div className="main_content">
      <SidebarComponent isHomePage={false}></SidebarComponent>
      <AboutComponent></AboutComponent>
    </div>
    </>
  )
}