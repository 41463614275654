import React, { useState, useEffect, useContext } from "react";
import { TabContext } from "../../App"
import {get_journal_data_by_entry} from "../../common/js/webservice"
import {
  Link,
  useParams,
} from "react-router-dom";
import {sendGAPageView} from "../../common/js/ga4.js"

const JournalEntryComponent = () => {
  const { id } = useParams();
  const {GetDataForPage} = useContext(TabContext)
  const [JournalData, setJournalData] = useState([]);
  const fetchData = async () => {
    let data = await get_journal_data_by_entry(id);
    if (data){
      setJournalData(data.data);
    }
  }
  
  useEffect(() => {
    fetchData();
    let entryID = id
    if (entryID.length == 0){
      entryID = ""
    }
    sendGAPageView("/journalentry/" + entryID, "Journal Entry")
  }, []);
  return (
    <>
      <div id="journal_container" className="flexCol">
        <div id="entry_box" className="flexCol">
            <div className="divEntryTitle">
                <h2><strong>{JournalData.entry_title}</strong></h2>
                <h3>{JournalData.entry_date}</h3>
            </div> 
            <div id="journal_main">
                <div className="journal_entry_body">
                {JournalData.entry_body? JournalData.entry_body.map(item => 
                              <p>{item}</p>
                      ) : <></>}
                </div>
            </div>
            <Link className="journal_nav" to={`/journal`}><span>BACK</span></Link>
        </div>
     </div>
    </>
  );
};

export default JournalEntryComponent;
