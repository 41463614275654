import ReactGA from 'react-ga4';

var sendGAEvent = (_category, _action, _label = "") => {
    try{
        ReactGA.event({
            category: _category,
            action: _action,
            label: _label,
        });
    } catch (error) {
        console.error("GA Event Failed");
    }
}
export var sendGAPageView = (relativePath, pageTitle) => {
    try{
        ReactGA.send({ 
            hitType: "pageview", 
            page: relativePath, 
            title: pageTitle
        });
    } catch (error) {
        console.error("GA PageView Failed");
    }
}
export var navClickedGAEvent = (navStr) => {
    if (navStr == "about") sendGAEvent("Navigation Internal", "About Clicked");
    if (navStr == "experience")sendGAEvent("Navigation Internal", "Experience Clicked");
    if (navStr == "contact")sendGAEvent("Navigation Internal", "Contact Clicked");
    if (navStr == "journal")sendGAEvent("Navigation Internal", "Journal Clicked");
    if (navStr == "name-side")sendGAEvent("Navigation Internal", "Name Clicked", "Sidebar");
    if (navStr == "name-main")sendGAEvent("Navigation Internal", "Name Clicked", "Home");
}
export var linkClickedGAEvent = (linkName) => {
    if (linkName == "github") sendGAEvent("Navigation External", "GitHub Icon Clicked");
    if (linkName == "linkedin") sendGAEvent("Navigation External", "LinkedIn Icon Clicked");
    if (linkName == "mail") sendGAEvent("Navigation External", "Mail Icon Clicked");
    if (linkName == "neopets") sendGAEvent("Navigation External", "Neopets Link Clicked");
}
export var readMoreClickedGAEvent = (stepNum) => {
    sendGAEvent("About Page", "Read More Clicked", stepNum);
}
export var experiencePageGAEvent = (interaction, projectID = "", tags = "") => {
    if (interaction == "filter-toggle") sendGAEvent("Experience Page", "Filter Toggled");
    if (interaction == "filter-selectall") sendGAEvent("Experience Page", "Filter Select All Clicked");
    if (interaction == "filter-tag") sendGAEvent("Experience Page", "Filter Tag Changed", tags);
    if (interaction == "project-toggle") sendGAEvent("Experience Page", "Project Toggled", projectID);
}
export var contactMeGAEvent = (interaction) => {
    if (interaction == "send") sendGAEvent("Contact Page", "Message Submit");
    if (interaction == "msg-fail") sendGAEvent("Contact Page", "Message Fail");
    if (interaction == "msg-success") sendGAEvent("Contact Page", "Message OK");
}