export var headerClickedToHomePage = (navObj) => {
    setTimeout(function (){
        navObj("/home");
    }, 0); 
}
export var homePageToAboutPage = (navObj) => {
    setTimeout(function (){
        navObj("/about");
    }, 0); 
}
export var aboutPageToExperiencePage = (navObj) => {
  setTimeout(function (){
      navObj("/experience");
  }, 0); 
}
export var animateHomePage = async() => {
  //bring down links 
  let links = document.getElementsByClassName("homeLinks")
  if (links.length > 0){
    links[0].style.opacity = 0;
    await links[0].animate([{ transform: "translateY(-20vh)", opacity: 0},{ transform: "translateY(0vh)", opacity: 1}],
      {
        easing: "ease-in-out",
        duration: 1000,
        fill: "forwards"
      });
  }
  //bring down name
  let mainName = document.getElementsByClassName("nameTitle")
  if (mainName.length > 0){
    mainName[0].style.opacity = 0;
    await mainName[0].animate([{ transform: "translateY(-50vh)", opacity: 0},{ transform: "translateY(0vh)", opacity: 1}],
      {
        delay: 0,
        easing: "ease-in-out",
        duration: 1500,
        fill: "forwards"
      });
  }
  //bring down subtitle
  let subName = document.getElementsByClassName("nameSubtitle")
  if (subName.length > 0){
    subName[0].style.opacity = 0;
    await subName[0].animate([{ transform: "translateY(-50vh)", opacity: 0},{ transform: "translateY(0vh)", opacity: 1}],
      {
        delay: 300,
        easing: "ease-in-out",
        duration: 2000,
        fill: "forwards"
      });
      //expand hr
      let linebreak = subName[0].querySelector("hr")
      if (linebreak != null){
        linebreak.style.width = 0;
        await linebreak.animate([{ width: "0%"},{ width: "50%"}],
        {
          delay: 2000,
          easing: "ease-in-out",
          duration: 2000,
          fill: "forwards"
        });
      }
  }
  let startBtn = document.getElementById("homeStart")
  if (startBtn != null){
    startBtn.style.opacity = 0
    await startBtn.animate([{ opacity: 0},{ opacity: 1}],
      {
        delay: 2300,
        easing: "ease-in-out",
        duration: 2000,
        fill: "forwards"
      });
  }
}
export var aboutPageInitAnimation = () => {return}
//hide contact form when done, restore if want to go back
export var hideShowContactForm = async (form, formHeightStr, messageContainer, hide = false) => {
    if (form){
      if (hide){
        //hide form
        form.style.overflowY = "hidden";
        let heightKeyFrames =[{ height: formHeightStr + 'px', padding: '1em'},{ height: '0px',  padding: '0' }];
        await form.animate(heightKeyFrames,
          {
            easing: "linear",
            duration: 500,
            fill: "forwards"
          });
        let hideKeyFrames =[{ opacity: 1},{ opacity: 0}];
        await form.animate(hideKeyFrames,
            {
              easing: "linear",
              duration: 100,
              fill: "forwards"
            });
          //show message container
          if (messageContainer){
            messageContainer.style.display = "flex";
          }
      }
      else{
        //hide message container
        if (messageContainer){
          messageContainer.style.display = "none";
        }
        //show/restore form
        form.style.overflowY = "unset";
        let heightKeyFrames =[{ height: '0px', padding: '0em'}, { height: formHeightStr + 'px', padding: '1em'}];
        await form.animate(heightKeyFrames,
          {
            easing: "ease-out",
            duration: 500,
            fill: "forwards"
          });
          let hideKeyFrames =[{ opacity: 0},{ opacity: 1}];
          await form.animate(hideKeyFrames,
              {
                easing: "linear",
                duration: 100,
                fill: "forwards"
          });
      }  
    }
}
//upon height restore - if validation errors appear we must re-adjust the height to accomodate extra space
export var adjustContactFormHeight = async(form, currHeightStr, adjustedHeightStr) =>{
  if (form && currHeightStr != adjustedHeightStr){
    let heightKeyFrames =[{ height: currHeightStr + 'px'}, { height: adjustedHeightStr + 'px'}];
    await form.animate(heightKeyFrames,
      {
        easing: "ease-out",
        duration: 100,
        fill: "forwards"
      });
  }
}